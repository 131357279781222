import { lazy } from 'react';
import { importMDX } from 'mdx.macro';

interface Blog {
  title: string;
  content: any;
}

const blogs: Record<string, Blog> = {};

blogs['growing-up-asian'] = {
  title: 'Growing up Asian',
  content: lazy(() => importMDX('./0-growing-up-asian.mdx'))
};

export default blogs;
