/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

function TitlePage({ children }: Props) {
  return (
    <div
      css={css`
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      {children}
    </div>
  );
}

export default TitlePage;
