/** @jsx jsx */
import { jsx } from '@emotion/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ColorTheme from './theme';

import Home from './pages/Home';
import BlogsHome from './pages/BlogsHome';
import Rach from './pages/Rach';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';
import BlogPage from './pages/BlogPage';
import LetterPage from './pages/LetterPage';
import MakeTen from './pages/MakeTen';

function App() {
  return (
    <ColorTheme>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/blogs" component={BlogsHome} />
          <Route path="/letters/:letterId" component={LetterPage} />
          <Route path="/blogs/:blogId" component={BlogPage} />
          <Route path="/rach" component={Rach} />
          <Route path="/login" component={Login} />
          <Route path="/make-10" component={MakeTen} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </ColorTheme>
  );
}

export default App;
