/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Suspense, Fragment, useContext } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import Media from 'react-media';
import Cookie from 'js-cookie';

import { rachCookieKey, rachCookieValue } from './Login';
import PageNotFound from './PageNotFound';

import letters from '../letters';
import BackButton from '../components/BackButton';
import Loader from '../components/Loader';
import ColorThemeToggle from '../components/ColorThemeToggle';
import { ColorThemeContext } from '../theme';

const Content = ({ letterId }: { letterId: string }) => {
  const LetterContent = letters[letterId].content;
  return <LetterContent />;
};

const LetterPage = (props: RouteComponentProps<{ letterId: string }>) => {
  const { colorTheme } = useContext(ColorThemeContext);

  if (Cookie.get(rachCookieKey) !== rachCookieValue) {
    return <PageNotFound />;
  }

  const { letterId } = props.match.params;
  if (!letters[letterId]) {
    return <Redirect to="/404" />;
  }
  return (
    <Media query="(max-width: 599px)">
      {mobile => (
        <Fragment>
          {!mobile && <BackButton linkTo="/rach" />}
          {!mobile && <ColorThemeToggle />}
          <div
            css={css`
              min-height: 100vh;
            `}
          >
            <div
              className="mdx"
              css={css`
                margin: 0 auto;
                padding: ${mobile ? '40px 40px' : '80px 180px'};
                max-width: 800px;
                line-height: 1.5;
              `}
            >
              <Suspense fallback={<Loader colorTheme={colorTheme} />}>
                <Content letterId={letterId} />
              </Suspense>
            </div>
          </div>
        </Fragment>
      )}
    </Media>
  );
};

export default LetterPage;
