/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ReactNode, MouseEventHandler } from 'react';

interface PageTitleProps {
  children: ReactNode;
  onClick?: MouseEventHandler;
}

function PageTitle(props: PageTitleProps) {
  return (
    <div
      css={css`
        position: fixed;
        cursor: default;
        user-select: none;
        top: 80px;
        left: 0;
        width: 100%;
        font-size: 32px;
      `}
    >
      <div
        css={css`
          margin: 0 auto;
          width: fit-content;
        `}
        {...props}
      />
    </div>
  );
}

export default PageTitle;
