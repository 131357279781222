/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useState, useContext } from 'react';
import Cookie from 'js-cookie';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import TitlePage from '../containers/TitlePage';
import { ColorThemeContext, COLOR_THEMES } from '../theme';

export const rachCookieValue = 'D8!Aqug75rlX7fJ%072j3kH5mc@h59pu';
export const rachCookieKey = 'access-rach';

const password = 'potatoindie1';

function Login(props: RouteComponentProps) {
  const { colorTheme } = useContext(ColorThemeContext);
  const [input, setInput] = useState('');
  if (Cookie.get(rachCookieKey) === rachCookieValue) {
    return <Redirect to="/rach" />;
  }
  return (
    <TitlePage>
      <div
        css={css`
          padding-bottom: 20px;
        `}
      >
        <input
          type="text"
          value={input}
          onChange={e => setInput(e.target.value)}
          css={css`
            padding: 10px 10px;
            border-radius: 3px;
            font-size: 16px;
            font-family: inherit;
            border: ${colorTheme === COLOR_THEMES.DARK
              ? 'none'
              : '1px solid black'};
            outline: none;
          `}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              if (input === password) {
                Cookie.set(rachCookieKey, rachCookieValue);
                props.history.push('/rach');
              } else {
                setInput('');
              }
            }
          }}
        />
      </div>
    </TitlePage>
  );
}

export default Login;
