/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { COLOR_THEMES } from '../theme';

interface LoaderProps {
  colorTheme: COLOR_THEMES;
}

export default ({ colorTheme }: LoaderProps) => (
  <div
    className={`la-ball-pulse la-2x ${
      colorTheme === COLOR_THEMES.DARK ? '' : 'la-dark'
    }`}
    css={css`
      margin: 40vh auto 0;
    `}
  >
    <div />
    <div />
    <div />
  </div>
);
