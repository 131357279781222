/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Fragment, useRef, useEffect, useContext } from 'react';
import Media from 'react-media';
import { RouteComponentProps } from 'react-router-dom';
import Typist from 'react-typist';
import { IoLogoLinkedin, IoLogoTwitter, IoLogoGithub } from 'react-icons/io';
import { IconType } from 'react-icons/lib/cjs';

import { colors } from '../constants';
import TitlePage from '../containers/TitlePage';
import MainLink from '../components/MainLink';
import PageTitle from '../components/PageTitle';
import { ColorThemeContext, COLOR_THEMES } from '../theme';
import ColorThemeToggle from '../components/ColorThemeToggle';

interface SocialLinkProps {
  href: string;
  Icon: IconType;
}
const SocialLink = ({ href, Icon }: SocialLinkProps) => {
  const { colorTheme } = useContext(ColorThemeContext);

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      css={css`
        padding: 0 8px;
      `}
    >
      <Icon
        size="32px"
        css={css`
          cursor: pointer;
          color: ${colorTheme === COLOR_THEMES.DARK ? 'white' : 'black'};
          &:hover {
            color: ${colors.PRIMARY};
          }
        `}
      />
    </a>
  );
};

function Home({ history }: RouteComponentProps) {
  const clickCounter = useRef(0);
  const firstClickTime = useRef(0);
  const fourthClickTime = useRef(0);
  const nameClickHandler = () => {
    clickCounter.current = clickCounter.current + 1;

    if (clickCounter.current === 1) {
      firstClickTime.current = Date.now();
      return;
    }

    const timeSinceFirstClick = Date.now() - firstClickTime.current;
    if (clickCounter.current in [2, 3] && timeSinceFirstClick > 600) {
      clickCounter.current = 0;
      return;
    }

    if (clickCounter.current === 4) {
      if (timeSinceFirstClick < 1500 || timeSinceFirstClick > 4000) {
        clickCounter.current = 0;
        return;
      }
      fourthClickTime.current = Date.now();
      return;
    }

    if (clickCounter.current === 5) {
      const timeSinceFourthClick = Date.now() - fourthClickTime.current;
      if (timeSinceFourthClick > 500) {
        clickCounter.current = 0;
        return;
      }
      history.push('/login');
      return;
    }
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement | void;

      if (
        target &&
        !(
          target.tagName === 'DIV' &&
          target.className.split(' ').includes('typed-title-name')
        )
      ) {
        clickCounter.current = 0;
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <Media query="(max-width: 599px)">
      {mobile => (
        <Fragment>
          {!mobile && <ColorThemeToggle />}
          <TitlePage>
            <PageTitle onClick={nameClickHandler}>
              <Typist
                className="typed-title-name"
                avgTypingDelay={120}
                startDelay={100}
                cursor={{
                  show: false
                }}
              >
                Kang
                <Typist.Delay ms={70} />
                -Wei <Typist.Delay ms={120} />
                Chan
              </Typist>
            </PageTitle>
            <div
              css={css`
                margin: 0 auto;
                margin-top: 20px;
              `}
            >
              <MainLink to="/make-10">Make Ten Calculator</MainLink>
            </div>
            <footer
              css={css`
                margin: 0 auto;
                position: fixed;
                margin-top: 100px;
                bottom: 8vh;
              `}
            >
              <div>
                <SocialLink
                  href="https://www.linkedin.com/in/kang-wei-chan"
                  Icon={IoLogoLinkedin}
                />
                <SocialLink
                  href="https://www.twitter.com/kangweichan"
                  Icon={IoLogoTwitter}
                />
                <SocialLink
                  href="https://www.github.com/kangweichan"
                  Icon={IoLogoGithub}
                />
              </div>
            </footer>
          </TitlePage>
        </Fragment>
      )}
    </Media>
  );
}

export default Home;
