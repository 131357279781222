/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import TitlePage from '../containers/TitlePage';
import MainLink from '../components/MainLink';

function PageNotFound() {
  return (
    <TitlePage>
      <div
        css={css`
          text-align: center;
        `}
      >
        <h1
          css={css`
            padding-bottom: 20px;
          `}
        >
          Page not found
        </h1>
        <div
          css={css`
            display: flex;
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              display: flex;
              margin: 0 auto;
            `}
          >
            <MainLink to="/">Go to home</MainLink>
          </div>
        </div>
      </div>
    </TitlePage>
  );
}

export default PageNotFound;
