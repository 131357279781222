/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useState, createContext, ReactNode } from 'react';
import Cookie from 'js-cookie';

const COLOR_THEME_KEY = 'color_theme';

export enum COLOR_THEMES {
  DARK = 'DARK',
  LIGHT = 'LIGHT'
}

export const ColorThemeContext = createContext({
  colorTheme: COLOR_THEMES.LIGHT,
  toggleTheme: () => {}
});

const isColorTheme = (cookie: string | undefined): cookie is COLOR_THEMES => {
  return (
    cookie !== undefined &&
    Object.values(COLOR_THEMES).includes(cookie as COLOR_THEMES)
  );
};

interface ColorThemeProps {
  children: ReactNode;
}

function ColorTheme({ children }: ColorThemeProps) {
  let cookie = Cookie.get(COLOR_THEME_KEY);
  let theme: COLOR_THEMES;
  if (!isColorTheme(cookie)) {
    theme = COLOR_THEMES.LIGHT;
    Cookie.set(COLOR_THEME_KEY, COLOR_THEMES.LIGHT);
  } else {
    theme = cookie;
  }
  const [colorTheme, setColorTheme] = useState<COLOR_THEMES>(theme);

  return (
    <ColorThemeContext.Provider
      value={{
        colorTheme,
        toggleTheme: () => {
          const newTheme =
            colorTheme === COLOR_THEMES.DARK
              ? COLOR_THEMES.LIGHT
              : COLOR_THEMES.DARK;
          setColorTheme(newTheme);
          Cookie.set(COLOR_THEME_KEY, newTheme);
        }
      }}
    >
      <div
        css={css`
          background-color: ${colorTheme === COLOR_THEMES.DARK
            ? '#0c0c0c'
            : 'white'};
          color: ${colorTheme === COLOR_THEMES.DARK ? '#f0f0f0' : 'black'};
        `}
      >
        {children}
      </div>
    </ColorThemeContext.Provider>
  );
}

export default ColorTheme;
