/** @jsx jsx */
import { Fragment } from 'react';
import { css, jsx } from '@emotion/core';
import Media from 'react-media';

import TitlePage from '../containers/TitlePage';
import BackButton from '../components/BackButton';
import PageTitle from '../components/PageTitle';
import MainLink from '../components/MainLink';
import ColorThemeToggle from '../components/ColorThemeToggle';

function Blogs() {
  return (
    <Media query="(max-width: 599px)">
      {mobile => (
        <Fragment>
          {!mobile && <BackButton linkTo="/" />}
          {!mobile && <ColorThemeToggle />}
          <TitlePage>
            <PageTitle>Blogs</PageTitle>
            <div
              css={css`
                display: flex;
                margin: 0 auto;
                margin-top: 40px;
              `}
            >
              <MainLink to="/blogs/growing-up-asian">Growing up Asian</MainLink>
            </div>
          </TitlePage>
        </Fragment>
      )}
    </Media>
  );
}

export default Blogs;
