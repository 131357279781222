/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Media from 'react-media';

import letters from '../letters';
import BackButton from '../components/BackButton';
import MainLink from '../components/MainLink';
import ColorThemeToggle from '../components/ColorThemeToggle';
import Cookie from 'js-cookie';
import { RouteComponentProps } from 'react-router-dom';

import { rachCookieKey, rachCookieValue } from './Login';
import PageNotFound from './PageNotFound';

function Rach(props: RouteComponentProps) {
  if (Cookie.get(rachCookieKey) !== rachCookieValue) {
    return <PageNotFound />;
  }
  return (
    <Media query="(max-width: 599px)">
      {(mobile) => (
        <div
          css={css`
            min-height: 100vh;
          `}
        >
          {!mobile && <BackButton linkTo="/" />}
          {!mobile && <ColorThemeToggle />}
          <div
            css={css`
              display: flex;
              position: absolute;
              top: 80px;
              margin: 0 auto;
              width: 100vw;
              justify-content: center;
              font-size: 32px;
            `}
          >
            Hey Rach, I love you!
            <span
              css={css`
                color: red;
                padding-left: 4px;
              `}
            >
              &#x2764;
            </span>
          </div>
          <div
            css={css`
              margin: 0 auto;
              padding-top: 120px;
            `}
          >
            {Object.keys(letters)
              .reverse()
              .map((letterKey) => (
                <MainLink key={letterKey} to={`/letters/${letterKey}`}>
                  {letters[letterKey].title}
                </MainLink>
              ))}
          </div>
        </div>
      )}
    </Media>
  );
}

export default Rach;
