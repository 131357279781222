/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useContext } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { colors } from '../constants';
import { ColorThemeContext, COLOR_THEMES } from '../theme';

interface BackButtonProps {
  linkTo: string;
}

function BackButton({ linkTo }: BackButtonProps) {
  const { colorTheme } = useContext(ColorThemeContext);
  return (
    <Link to={linkTo}>
      <div
        css={css`
          display: flex;
          position: absolute;
          left: 80px;
          top: 80px;
        `}
      >
        <div
          css={css`
            z-index: 1;
            display: inline-block;
            height: 22px;
            width: 22px;
            padding: 10px;
            border-radius: 50%;
            cursor: pointer;
            line-height: 1;
            color: black;
            background-color: ${colorTheme === COLOR_THEMES.DARK
              ? 'white'
              : 'rgba(0, 0, 0, 0.1)'};

            &:hover {
              opacity: 0.8;
              background-color: ${colors.PRIMARY};
            }
          `}
        >
          <IoIosArrowBack size="22px" />
        </div>
      </div>
    </Link>
  );
}

export default BackButton;
