/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface NavLinkProps {
  to: string;
  children: ReactNode;
}

export default ({ to, children }: NavLinkProps) => (
  <div
    css={css`
      margin: 80px 10px;
      font-weight: 200;
      font-size: 3em;
      text-align: center;

      @media only screen and (max-width: 599px) {
        text-decoration: underline;
        font-size: 2em;
      }
    `}
  >
    <Link to={to}>{children}</Link>
  </div>
);
