/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useContext } from 'react';
import { IoIosMoon, IoIosSunny } from 'react-icons/io';

import { colors } from '../constants';
import { ColorThemeContext, COLOR_THEMES } from '../theme';

function ColorThemeToggle() {
  const { colorTheme, toggleTheme } = useContext(ColorThemeContext);
  return (
    <div
      css={css`
        display: flex;
        position: absolute;
        right: 80px;
        top: 80px;
      `}
    >
      <div
        css={css`
          z-index: 1;
          display: inline-block;
          height: 22px;
          width: 22px;
          padding: 10px;
          border-radius: 50%;
          cursor: pointer;
          user-select: none;
          line-height: 1;
          color: black;
          background-color: ${colorTheme === COLOR_THEMES.DARK
            ? 'white'
            : 'rgba(0, 0, 0, 0.1)'};

          &:hover {
            opacity: 0.8;
            background-color: ${colors.PRIMARY};
          }
        `}
        onClick={toggleTheme}
      >
        {colorTheme === COLOR_THEMES.DARK ? (
          <IoIosMoon size="22px" />
        ) : (
          <IoIosSunny size="22px" />
        )}
      </div>
    </div>
  );
}

export default ColorThemeToggle;
