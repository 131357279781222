/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Suspense, Fragment, useContext } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import Media from 'react-media';

import blogs from '../blogs';
import BackButton from '../components/BackButton';
import Loader from '../components/Loader';
import ColorThemeToggle from '../components/ColorThemeToggle';
import { ColorThemeContext } from '../theme';

const Content = ({ blogId }: { blogId: string }) => {
  const BlogContent = blogs[blogId].content;
  return <BlogContent />;
};

const BlogPage = (props: RouteComponentProps<{ blogId: string }>) => {
  const { colorTheme } = useContext(ColorThemeContext);

  const { blogId } = props.match.params;
  if (!blogs[blogId]) {
    return <Redirect to="/404" />;
  }
  return (
    <Media query="(max-width: 599px)">
      {mobile => (
        <Fragment>
          {!mobile && <BackButton linkTo="/blogs" />}
          {!mobile && <ColorThemeToggle />}
          <div
            css={css`
              min-height: 100vh;
            `}
          >
            <div
              className="mdx"
              css={css`
                margin: 0 auto;
                padding: ${mobile ? '32px 6%' : '80px 180px'};
                max-width: 750px;
                line-height: 1.5;
              `}
            >
              <Suspense fallback={<Loader colorTheme={colorTheme} />}>
                <Content blogId={blogId} />
              </Suspense>
            </div>
          </div>
        </Fragment>
      )}
    </Media>
  );
};

export default BlogPage;
